@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  border: 10px solid #7FCAC4;
  min-height: 100vh;
  padding: 0rem 2rem;
  background-color: #fff;
  /* background: radial-gradient(64.9% 34.2308% at 0% 0%, rgba(138, 255, 255, 0.45) 0%, rgb(255, 255, 255) 100%); */
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
}

h1 {
  font-size: 2.4rem;
  font-weight:bold;
  font-family: 'Roboto Mono', monospace;
  color: #000;
}

span {
  font-size: 2.4rem;
  font-family: 'Roboto Mono', monospace;
  color: #000;
}

/* .price--line-through{
  background-color: transparent;
  background-image: repeating-linear-gradient(178deg, transparent 0%, transparent 46%, #ff0000 50%, transparent 82%, transparent 100%);
  width: 290px;
} */

h2 {
  font-size: 2rem;
  font-family: 'Roboto Mono', monospace;
  color: #000;
  margin-bottom: 2rem;
  font-weight:bold;
}

h3 {
  font-size: 1.4rem;
  font-family: 'Roboto Mono', monospace;
  color: #000;
  margin-bottom: 2rem;
  background-image: linear-gradient(0deg, #D1F0EE 42%, #ffffff calc(16% + -3px));
  width: 310px;
}

p {
  font-size: 1.1rem;
  font-family: 'Roboto Mono', monospace;
  color: #000;
}
/* NAVIGATION */

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1154px;
  width: 100%;
  padding: 3rem 0rem;
}

.navigation img {
  width: 60px;
}

.navigation button {
  font-family: 'Roboto Mono', monospace;
  font-weight: bold;
  color: #fff;
  background-color: #000;
  padding: 1rem 3rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.navigation button:hover {
  background-color: #474747;
  transition: 0.6s;
}

/* HERO */

.heroo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  width: 100%;
  margin-bottom: 6rem;
}

.left{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.right{
  width: 100%;
}

.right img {
  width: 100%;
}

/* ABOUT */

.about {
  margin-bottom: 6rem;
}

.about p {
  margin-bottom: 2rem;
}

.about h2 {
  background: #dff3f2;
  display: inline-block;
  background-image: linear-gradient(0deg, #D1F0EE 42%, #ffffff calc(16% + -3px))
}

/* THOUGHTS */

.thoughts{
  margin-bottom: 6rem;
  /* margin-right: 2rem; */
}

.thoughts h2 {
  background: #dff3f2;
  display: inline-block;
  background-image: linear-gradient(0deg, #D1F0EE 42%, #ffffff calc(16% + -3px))
}

.thoughts p.beem {
  font-weight:500;
  /* color: #7FCAC4; */
  color: #000;
}

.line-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2rem 0rem;
  border-top: solid 3px #000;
}

.line-item a {
  text-decoration: none;
}

.line-item.last {
  border-bottom: solid 3px #000;
}

.thoughts .title {
  margin-right: 2rem;
}

.read {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconn {
  margin-left: 10px;
}

/* .test:hover ~ .iconn {
  color: #fff;
}  */

/* .sibling-hover {
  cursor: pointer;
}
.sibling-hover:hover ~ .sibling-highlight {
  background-color: red;
  color: white;
}

/* VIDEOS */

.videos {
  width: 100%;
  margin-bottom: 6rem;
}


.videos h2 {
  background: #dff3f2;
  display: inline-block;
  background-image: linear-gradient(0deg, #D1F0EE 42%, #ffffff calc(16% + -3px))
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  width: 100%;
}

.video-left {
  width: 100%;
}

.video-right {
  width: 100%;
}

/* NEWSLETER */

form {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.newsletter{
  width: 100%;
  margin-bottom: 6rem;
}

.newsletter p {
  margin-bottom: 2rem;
}

.box {
  background-color: #F5F5F5;
  padding: 3rem;
}

/* .marketing {
  display: flex;
  justify-content: space-between;
} */

input {
  width: 80%;
  font-size: 1rem;
  font-family: 'Roboto Mono', monospace;
  color: #000;
  background-color: #fff;
  padding: 0rem 1rem;
  border: none;
}

.marketing button {
  font-family: 'Roboto Mono', monospace;
  font-weight: bold;
  color: #fff;
  background-color: #000;
  padding: 1rem 3rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  width: 20%;
  margin-left: 20px;
}


.marketing button:hover {
  background-color: #474747;
  transition: 0.6s;
}

/* FOOTER */

.footerr{
  width: 100%;
  margin: 0rem 0rem 6rem;
  text-align: center;
}

.footerr img {
  display: initial;
  /* margin-bottom: 6rem; */
}

.footerr .copyright {
  width: 100%;
  font-size: 1.1rem;
  font-family: 'Roboto Mono', monospace;
  color: #A8A8A8;
  margin: 3rem 0rem;
}


.footerr .copyright p {
  color: #A8A8A8;
  font-weight: 900;
}

.footerr .contact {
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 24px;
  justify-content: center;
  overflow: hidden;
  width: 100%;
}

.footerr svg {
  fill: #000;
  opacity: 0.3;
}

.footerr svg:hover {
 opacity: 1;
 transition: 0.5s;
}

/* .footerr .bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
} */




@media only screen and (max-width: 800px) {
  
  p {
    font-size: 0.9rem;
  }

  .navigation {
    padding: 2rem 0rem;
}

  .heroo {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    margin-top: 4rem;
  }

  .container {
    grid-template-columns: 1fr;
}

  form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }

  input {
    width: 100%;
    padding: 1rem 1rem;
  }

  .marketing button {
    width: 100%;
    margin-left: 0px;
  }

}

/* 
"react-reveal": "^1.2.2",

*/

@media only screen and (max-width: 600px) {

  body {
    padding: 0rem 1rem;
  }

  h2 {
    font-size: 1.7rem;
  }

  .navigation {
    padding: 1rem 0rem;
}

  .navigation img {
  width: 40px;
}

.navigation button {
  padding: 0.8rem 2rem;
}

h1 {
  font-size: 2rem;
}

h3 {
  font-size: 1.2rem;
  width: 300px;
}

p {
  font-size: 0.8rem;
}

.left {
  align-items: center;
}

.heroo h3 {
  width: 267px;
}

.heroo {
  margin-bottom: 4rem;
}

.about {
  margin-bottom: 4rem;
}

.thoughts {
  margin-bottom: 4rem;
}

.videos {
  margin-bottom: 4rem;
}

.newsletter {
  margin-bottom: 4rem;
}

.footerr {
  margin: 0 0 4rem;
}

.box {
  padding: 2rem;
}

}


